.componentXl {
  font-size: var(--font-size-60);
  font-size: var(--font-size-60-120);
  font-style: italic;
  line-height: var(--line-height-heading-alt);
}

.componentLg {
  font-size: var(--font-size-32);
  font-size: var(--font-size-32-60);
  font-style: italic;
  line-height: var(--line-height-heading);
  font-weight: var(--font-weight-regular);
}

.componentMd {
  font-size: var(--font-size-24);
  font-size: var(--font-size-24-28);
  font-style: italic;
  line-height: var(--line-height-heading);
}

.componentSm {
  font-size: var(--font-size-18);
  font-size: var(--font-size-18-22);
  font-weight: var(--font-weight-regular);
  font-style: italic;
  line-height: var(--line-height-heading);
}

.componentBase {
  font-family: var(--font-family-base);
}
