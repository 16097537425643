.floatingOverlay {
  scrollbar-gutter: stable; /* Reserve scrollbar space for when content is not high enough */
}

._rootOverlayBackground {
  background-color: var(--color-gray-900--25);
  width: 100%;
  min-height: 100%;
  position: relative;
  overflow-y: scroll;

  @media (--viewport-md) {
    overflow-y: hidden;
  }

  ._rootOverlay {
    position: absolute;
    left: 0;
    top: var(--size-16);
    right: var(--size-32);

    @media (--viewport-md) {
      position: static;
    }
  }

  & > .containerLayout {
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
  }

  @media (--viewport-md) {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px 0;
    padding: var(--size-40) 0;
  }
}

._rootOverlay {
  background-color: var(--color-white);
  border-radius: 0 var(--radius-12) 0 0;
  min-height: 100%;
  display: flex;
  flex-direction: column;

  @media (--viewport-md) {
    border-radius: var(--radius-12);
  }
}

.componentOverlayHeader {
  display: flex;
  padding: var(--size-16);
  justify-content: flex-end;
  gap: var(--size-8);

  @media (--viewport-md) {
    padding: var(--size-32);
  }
}

.componentIconButton {
  padding: var(--size-12);
  background-color: var(--color-gray-800);
  color: var(--color-white);
  border-radius: var(--radius-circular);

  & > .iconButtonContent {
    width: 18px;
    height: 18px;
  }
}

.contentWrapper {
  padding: 0 var(--size-32) var(--size-16);

  @media (--viewport-md) {
    padding: 0 var(--size-80) var(--size-96);
  }
}
