.component {
  display: flex;
  background-color: var(--color-gray-800);
  flex-direction: column;

  @media (--viewport-md) {
    flex-direction: row;
  }

  & > .playerWrapper {
    flex-grow: 1;
  }

  & > .chapters {
    min-width: 150px;
    min-height: 175px;
  }
}

.playerWrapper {
  position: relative;
  aspect-ratio: 16 / 9;

  & > .player {
    position: absolute;
    top: 0;
    left: 0;
  }
}

.chapters {
  color: var(--color-white);
  position: relative;
  overflow-y: scroll;

  & > .chaptersWrapper {
    position: absolute;
    width: 100%;
  }
}

.chaptersWrapper {
  display: flex;
  gap: var(--size-8);
  padding: var(--size-16);
  flex-direction: column;
}

.header {
  font-size: var(--font-size-18);
  font-style: italic;
}

.componentTimestampButton {
  font-size: var(--font-size-12);
  text-align: left;
  display: block;
  border-radius: var(--radius-4);
  padding: var(--size-8);

  &:hover {
    background-color: var(--color-white--15);
  }

  & > .title {
    margin-bottom: var(--size-8);
  }
}

.title {
  display: block;
  line-height: var(--line-height-heading);
}

.timestamp {
  border-radius: var(--radius-4);
  padding: var(--size-4);
  background-color: var(--color-blue-700);
}
