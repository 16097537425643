.component {
  display: grid;
  gap: var(--size-16);
}

.componentFaqItem {
  background-color: var(--tertiary-element-background-color);
  padding: var(--size-24);
  border-radius: var(--radius-12);

  & > .questionAnswer {
    margin-top: var(--size-16);
  }
}

.questionSummary {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: var(--size-16);
  font-style: italic;
  color: var(--color);
  font-size: var(--font-size-20);

  &:hover {
    & > .chevron {
      background-color: var(--color);
      color: var(--tertiary-element-background-color);
    }
  }

  &::-webkit-details-marker {
    display: none;
  }

  & > .chevron {
    height: var(--size-36);
    width: var(--size-36);
    flex-shrink: 0;
  }
}

.chevron {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--color);
  border-radius: var(--radius-circular);
}

.chevronOpen {
  transform: rotate(-180deg);
}
