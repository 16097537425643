.componentBase {
  display: inline-block;
  padding: var(--size-12) var(--size-32);
  border-radius: var(--radius-4);
  font-weight: var(--font-weight-600);
}

.componentPrimary {
  background-color: var(--primary-element-background-color);
  color: var(--primary-element-color);
  box-shadow: 0 0 0 0 transparent;
  transition:
    box-shadow var(--duration-150) var(--ease-out-sine),
    opacity var(--duration-150) var(--ease-out-sine);

  &:hover,
  &:focus {
    box-shadow: var(--shadow-sm);
    opacity: 0.9;
  }

  &:disabled {
    opacity: 0.5;
  }
}
