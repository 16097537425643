.componentBase {
  display: flex;
  justify-content: center;
  padding: 0 var(--container-padding);

  & > .layout {
    width: 100%;
  }
}

.componentLg {
  & > .layout {
    max-width: var(--container-max-width-lg);
  }
}
