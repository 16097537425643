/* CARD */

.component {
  transition: transform 0.1s;

  /* Safari support for transforming scale */
  & > * {
    transform: translate3d(0, 0, 0);
  }

  @media (--viewport-md) {
    transform: scale(1);
  }

  &.hover {
    &:hover {
      @media (--viewport-md) {
        transform: scale(1.05);
      }
    }
  }

  & > .contentWrapper {
    color: var(--color);
    border-radius: var(--radius-12);
    overflow: hidden;
    background-color: var(--background-color);
    box-shadow: var(--shadow-sm);
  }
}

/* ARTICLE CARD */

.componentArticle {
  text-align: center;
}

.articleContent {
  padding: var(--size-16) var(--size-32) var(--size-32);

  @media (--viewport-md) {
    padding: var(--size-24) var(--size-24) var(--size-40);
  }

  & > .tagsLayout {
    margin-bottom: var(--size-24);
  }

  & > .intro {
    margin-top: var(--size-16);
  }

  & > .title {
    color: var(--color-blue-500);
  }

  & > .intro {
    display: block;
    font-size: var(--font-size-16);
    line-height: var(--line-height-card-intro);

    @media (--viewport-sm) {
      font-size: var(--font-size-14);
    }
  }
}

/* VIDEO CARD */

.componentVideo {
  position: relative;

  & > .wrapperLayout {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
  }
}

.videoWrapper {
  background: rgb(0, 0, 0, 0.2);
  background: linear-gradient(0deg, var(--color-black--50) 20%, var(--color-black--20) 100%);
  display: flex;
  align-items: flex-end;
}

.componentVideoContent {
  padding: var(--size-16);
  display: flex;
  gap: var(--size-12);
  align-items: center;

  & > .playIconLayout {
    flex-shrink: 0;
    width: var(--size-40);
    height: var(--size-40);

    @media (--viewport-md) {
      height: var(--size-64);
      width: var(--size-64);
    }
  }
}

.componentPlayIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-black--20);
  color: var(--color-white);
  border-radius: var(--radius-circular);

  & > .iconLayout {
    width: var(--size-16);
    height: var(--size-16);

    @media (--viewport-md) {
      width: var(--size-24);
      height: var(--size-24);
    }
  }
}

/* FACT CARD */

.componentFact {
  text-align: center;
  padding: var(--size-16) var(--size-32) var(--size-32);

  & > .valueLayout {
    margin-top: var(--size-24);
  }

  & > .suffixLayout {
    margin-top: var(--size-16);
  }
}

.componentFactValue {
  word-break: break-all;
  line-height: var(--line-height-heading-alt);
  font-size: var(--font-size-40);
  font-size: var(--font-size-40-45);
  font-style: italic;
}

.componentFactSuffix {
  font-size: var(--font-size-12);
  font-size: var(--font-size-12-14);
}

/* PODCAST CARD */

.componentPodcastBase {
  --small-variant-height: 80px;
  --large-variant-height: 232px;

  display: grid;
  position: relative;
  z-index: 0;

  & > .skeletonLayout {
    grid-area: 1 / -1;
    z-index: 0;
  }

  & > .frameLayout {
    grid-area: 1 / -1;
    width: 100%;
    z-index: 1;
  }

  @keyframes pulse {
    from { opacity: calc(var(--skeleton-opacity) * 0.75); }
    to { opacity: var(--skeleton-opacity); }
  }
}

.componentFrame {
  --opacity: 0;

  border: 0;
  display: flex;
  opacity: var(--opacity);
  transition: opacity 350ms ease;

  & > * {
    flex: 1 1 auto;
  }

  &.isReady {
    --opacity: 1;
  }
}

.componentSkeleton {
  --skeleton-opacity: 0.2;
  --play-button-size: 32px;
  --skeleton-child-animation: none;

  &:not(.isError) {
    --skeleton-child-animation: pulse 1s alternate infinite forwards ease;
  }

  display: grid;
  gap: 10px 15px;
  padding: var(--skeleton-padding);
  grid-template-rows: var(--skeleton-grid-rows);
  grid-template-columns: var(--skeleton-cover-size) 1fr var(--play-button-size);
  grid-template-areas: var(--skeleton-grid-areas);

  & > .imageLayout {
    grid-area: image;
  }

  & > .titleLayout {
    grid-area: title;
  }

  & > .playLayout {
    grid-area: play;
  }
}

.imageSkeleton,
.titleSkeleton {
  border-radius: 8px;
  opacity: var(--skeleton-opacity);
  background-color: var(--color-white);
  animation: var(--skeleton-child-animation);
}

.playSkeleton {
  border-radius: 50%;
  opacity: var(--skeleton-opacity);
  background-color: var(--color-white);
  animation: var(--skeleton-child-animation);
}

.componentPodcastSmall {
  --skeleton-padding: 8px;
  --skeleton-cover-size: 64px;
  --skeleton-grid-rows: 5px 5px 32px;
  --skeleton-grid-areas:
    'image  title ...'
    'image  title ...'
    'image  ...   play';

  & > .frameLayout {
    height: var(--small-variant-height);
  }
}

.componentPodcastLarge {
  --skeleton-padding: 16px;
  --skeleton-cover-size: 160px;
  --skeleton-grid-rows: 20% 1lh 1fr 32px;
  --skeleton-grid-areas:
    'image  ...   ...'
    'image  title ...'
    'image  ...   ...'
    '...    ...   play';

  @media screen and (min-width: 566px) {
    --skeleton-cover-size: 200px;
    --skeleton-grid-areas:
      'image  ...   ...'
      'image  title ...'
      'image  ...   ...'
      'image  ...   play';
  }

  & > .frameLayout {
    height: var(--large-variant-height);
  }
}
