.component {
  display: flex;
  gap: var(--size-8);
  overflow: hidden;
  flex-wrap: wrap;

  &::-webkit-scrollbar {
    display: none;
  }
}

.componentFilterButton {
  color: var(--color);
  border: 1px solid var(--border-color);
  padding: var(--size-8) var(--size-16);
  font-size: var(--font-size-16);
  display: flex;
  align-items: center;
  gap: var(--size-8);
  border-radius: var(--radius-8);

  &:hover:not(.active) {
    @media (--viewport-md) {
      background-color: var(--border-color);
    }
  }

  &.active {
    background-color: var(--accent-color);
    color: var(--background-color);
  }
}
