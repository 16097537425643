.component {
  display: block;
  padding: var(--size-20) var(--size-64) var(--size-20) var(--size-24);
  background-color: var(--tertiary-element-background-color);
  border-radius: var(--radius-12);
  font-size: var(--font-size-20);
  font-style: italic;
  position: relative;
  color: var(--color);

  & > .icon {
    position: absolute;
    right: var(--size-16);
    top: 50%;
    width: var(--size-36);
    height: var(--size-36);
  }

  &:hover {
    & > .icon {
      background-color: var(--color);
      color: var(--tertiary-element-background-color);
    }
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(-50%);
  border: var(--color) solid 1px;
  border-radius: var(--radius-circular);
  padding: var(--size-8);
}
