.component {
  position: relative;
  z-index: 0;

  & > .suggestions {
    z-index: 1;
    position: absolute;
    width: 100%;
    top: 50px;
  }

  &:focus-within {
    & > .suggestions {
      /* Safari requires direct child selection for this to work */
      /* stylelint-disable-next-line kaliber/layout-related-properties */
      display: flex;
    }
  }
}

.suggestions {
  display: none;
  gap: 20;
  flex-direction: column;
  padding: 10px;
  border: 1px solid var(--color-black);
  border-top: none;
  background-color: var(--color-white);
  color: var(--color-black);
  border-radius: 0 0 var(--radius-12) var(--radius-12);
}

.suggestion {
  padding: 10px;

  &:hover {
    background-color: var(--color-gray-300);
  }

  &:first-of-type {
    border-top: 1px solid var(--hairline-color);
  }

  & > em {
    font-weight: 800;
  }
}

.searchField {
  background-color: var(--color-white);
  border: 1px solid var(--border-color);
  color: var(--color);
  padding: var(--size-20) var(--size-64);
  border-radius: var(--radius-12);

  &::placeholder {
    color: var(--color-gray-500);
    opacity: 1; /* Firefox */
  }

  &:focus {
    outline: none;
  }

  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.searchSubmit {
  display: none;
}

.searchForm {
  position: relative;
  display: flex;
  align-items: center;

  & > .searchField {
    width: 100%;
  }

  & > .searchIconLayout {
    position: absolute;
    left: var(--size-24);
    width: var(--size-24);
  }

  & > .clearSearchButton {
    width: var(--size-32);
    position: absolute;
    right: 0;
    margin-right: var(--size-24);
  }
}

.clearSearchButton {
  padding: var(--size-8);
  color: var(--color-blue-600);
}
