.component {
  display: inline-flex;
  flex-wrap: wrap;
  gap: var(--size-8);
}

.alignCenter {
  justify-content: center;
}

.tag {
  color: var(--secondary-element-color);
  padding: var(--size-8) var(--size-8);
  background-color: var(--secondary-background-color);
  border-radius: var(--radius-4);
  font-weight: var(--font-weight-semi-bold);
  font-size: var(--font-size-10);
  letter-spacing: 2px;
}
